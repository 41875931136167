import React from 'react'
import ReceivablesForm from 'src/components/Forms/AntecipacaoRecebiveis/SoliciteProposta'

import { Section } from './style'

const AntecipeComInter = () => {
  return (
    <Section
      id='solicite-sua-proposta'
      className='py-5 d-flex align-items-center '
    >
      <div className='container my-md-4'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-xl-5 text-md-center text-xl-left'>
            <h2 className='fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white mb-4'>
              Antecipe com o Inter Empresas
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-white mb-md-5 mb-xl-0'>
              Preencha o formulário para entrar em contato com um de nossos especialistas
            </p>
          </div>
          <div className='col-12 col-xl-6 pl-xl-0'>
            <ReceivablesForm
              section='dobra_6'
              elementAction='submit'
              elementName='Enviar'
              sectionName='Antecipe com o Inter Empresas'
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AntecipeComInter
