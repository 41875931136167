import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ContentSection = styled.section`
  min-height: 624px;

  @media ${device.tablet} {
    min-height: 416px;
  }
  @media ${device.desktopLG} {
    min-height: 504px;
  }
  @media ${device.desktopXL} {
    min-height: 648px;
  }
`
