import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import ScrollTo from 'src/components/ScrollTo'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import SectionComponent from 'src/components/SectionComponent'

import OnLineEsemBurocraciasJSON from '../../assets/data/online-e-sem-burocracias.json'

import { Button } from '../../style'
import { IconList } from './style'

const OnlineEsemBurocracias = () => {
  const width = useWidth()

  return (
    <SectionComponent
      id='online-e-sem-burocracias'
      SectionStyles='py-5 bg-gray'
      RowStyles='justify-md-content-center'
      minHeight={{ sm: '640px', md: '636px', lg: '631px', xl: '721px' }}
    >
      <div className='col-12 mb-4'>
        <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-md-center text-grayscale--500'>
          Online e sem burocracias: aqui sua empresa só tem vantagens na hora de antecipar recebíveis
        </h2>
      </div>
      <IconList className='col-12 px-0'>
        {OnLineEsemBurocraciasJSON.map((item: {icon: string; description: string}) =>
          <div className='col-md-12 d-flex align-items-center flex-md-column mb-4' key={item.icon}>
            <div className='mr-2 mb-md-4'>
              <OrangeIcon size={width < WIDTH_MD ? 'MD' : 'XL'} color='#07605B' icon={item.icon} />
            </div>
            <div>
              <p className='fs-16 lh-19 text-grayscale--500 mb-0'>{item.description}</p>
            </div>
          </div>,
        )}
      </IconList>
      <div className='col-12 col-md-6 mt-3 mt-md-5 mx-md-auto'>
        <ScrollTo
          to='#solicite-sua-proposta'
          section='dobra_02'
          sectionName='Online e sem burocracias: aqui sua empresa só tem vantagens na hora de antecipar recebíveis'
          elementName='Antecipar com o Inter'
          title='Antecipar com o Inter'
        >
          <Button
            className='text-none'
          >
            Antecipar com o Inter
          </Button>
        </ScrollTo>
      </div>
    </SectionComponent>
  )
}

export default OnlineEsemBurocracias
