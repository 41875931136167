import React from 'react'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { WIDTH_MD } from 'src/styles/breakpoints'

import usePageQuery from '../../pageQuery'

import { ContentSection } from './style'
import { LinkButton } from '../../style'

const AindaNaoTemConta = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const width = useWidth()

  const urlPJ = 'https://conta-digital-pj.inter.co/login'

  return (
    <ContentSection
      className='py-5 bg-grayscale--100 d-flex position-relative justify-content-center justify-content-md-between align-items-center'
    >
      <div className='container'>
        <div className='row align-items-center justify-content-md-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            {width <= WIDTH_MD
              ? <Img
                  fluid={data.aindaNaoTemContaMobileTablet.fluid}
                  className='mb-4 mb-md-0'
                  alt='Cliente Inter Empresas segurando seu cartão PJ. Ao lado, vemos um notebook com a tela do Internet Banking Inter em destaque.'
                />
            : <Img
                fluid={data.aindaNaoTemContaDesktopLgXl.fluid}
                alt='Cliente Inter Empresas segurando seu cartão PJ. Ao lado, vemos um notebook com a tela do Internet Banking Inter em destaque.'
              />
            }
          </div>
          <div className='col-12 col-md-6 col-xl-5 pr-xl-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-3'>
              Ainda não tem conta no Inter Empresas?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4 pb-lg-2 mb-xl-5'>
              É 100% gratuito, digital e sem anuidades. Simplifique a rotina do seu negócio com um Super App que te leva além!
            </p>
            <LinkButton
              href={urlPJ}
              className='text-none d-flex'
              target='_blank'
              title='Abrir minha conta'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  section_name: 'Ainda não tem conta no Inter Empresas?',
                  element_action: 'click button',
                  element_name: 'Abrir minha conta',
                  redirect_url: urlPJ,
                 })
              }}
            >
              Abrir minha conta
            </LinkButton>
          </div>
        </div>
      </div>
    </ContentSection>
  )
}

export default AindaNaoTemConta
