import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'

import usePageQuery from '../../pageQuery'
import { ContentHeader } from './style'
import { Button } from './../../style'
import { WIDTH_MD } from 'src/styles/breakpoints'

const Hero = () => {
  const data = usePageQuery()
  const width = useWidth()

  return (
    <ContentHeader
      id='faca-sua-antecipacao'
      className='py-5 bg-white d-flex position-relative justify-content-center justify-content-md-between align-items-center'
    >
      <div className='container'>
        <div className='row align-items-center justify-content-md-between'>
          <div className='col-12 col-md-6'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-3'>
              Faça sua antecipação de recebíveis e receba pagamentos à vista
            </h1>
            <p className='fs-18 lh-22 text-grayscale--500 mb-4'>
              Conte com o Inter Empresas para antecipar recebíveis de forma mais simples, manter fluxo de caixa em dia e ter mais
              capital de giro para o seu negócio
            </p>
            <ScrollTo
              to='#solicite-sua-proposta'
              section='dobra_01'
              sectionName='Faça sua antecipação de recebíveis e receba pagamentos à vista'
              elementName='Antecipar com o Inter'
              title='Antecipar com o Inter'
              ariaLabel='Antecipar Recebíveis com o Inter'
            >
              <Button className='text-none'>
                Antecipar com o Inter
              </Button>
            </ScrollTo>
          </div>
          {width >= WIDTH_MD &&
            <div className='col-12 col-md-6 col-lg-5'>
              <Img
                fluid={data.headerAntecipacaoRecebiveis.fluid}
                className='float-lg-right'
                alt='Empreendedor sorrindo enquanto solicita uma antecipação de recebíveis com as melhores taxas do mercado no Super App Inter Empresas'
              />
            </div>
          }
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
