export default [
  {
    imagem: require('../images/logo-mrv.svg'),
  },
  {
    imagem: require('../images/logo-mosaic.svg'),
  },
  {
    imagem: require('../images/logo-anglo-gold.png'),
  },
  {
    imagem: require('../images/logo-vale.png'),
  },
  {
    imagem: require('../images/logo-petrobras.svg'),
  },
  {
    imagem: require('../images/mais-de-cem-empresas-parceiras.svg'),
  },
  {
    imagem: require('../images/logo-stellantis.png'),
  },
]
