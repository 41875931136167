import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import usePageQuery from '../../pageQuery'

import { ContentHeader } from './style'
import { Button } from './../../style'

const SuaParceriaSimplificada = () => {
  const data = usePageQuery()
  const width = useWidth()

  return (
    <ContentHeader
      id='sua-parceria-simplicada'
      className='py-5 d-flex position-relative justify-content-center justify-content-md-between align-items-center'
    >
      <div className='container'>
        <div className='row align-items-center justify-content-md-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            {width <= WIDTH_MD
              ? <Img
                  fluid={data.suaParceriaSimplificadaMobileTablet.fluid}
                  className='mb-4 mb-md-0'
                  alt='Cliente Inter sorridente'
                />
            : <Img
                fluid={data.suaParceriaSimplificadaDesktopLgXl.fluid}
                alt='Cliente Inter sorridente'
              />
            }
          </div>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600 mb-3'>
              Sua parceria simplificada: antecipe seus recebíveis de grandes empresas
            </h2>
            <p className='fs-14 lh-16 fs-xl-18 lh-xl-22 text-white mb-4'>
              Se seus clientes realizam pagamento a prazo e são empresas parceiras do Inter, sua rotina de fornecimento
              pode ser mais fácil com nossa solução de antecipação de recebíveis.
            </p>
            <p className='fs-14 lh-16 fs-xl-18 lh-xl-22 text-white mb-4 pb-lg-3'>
              Receba os valores de seus serviços à vista e garanta mais tranquilidade com um fluxo de caixa saudável.
            </p>
            <ScrollTo
              to='#solicite-sua-proposta'
              section='dobra_03'
              sectionName='Sua parceria simplificada: antecipe seus recebíveis de grandes empresas'
              elementName='Antecipar com o Inter'
              title='Antecipar com o Inter'
            >
              <Button className='text-none bg-white'>
                Antecipar com o Inter
              </Button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default SuaParceriaSimplificada
