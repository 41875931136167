import { device } from 'src/styles/breakpoints'
import { green, white } from 'src/styles/colors'
import styled from 'styled-components'

export const StepperContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
    flex: 1;
  }

  .stepper-item::before {
    content: "";
    position: absolute;
    border-bottom: 2px solid ${green[500]};
    width: 100%;
    top: 26px;
    left: -50%;
    z-index: 2;
  }

  .stepper-item::after {
    content: "";
    position: absolute;
    border-bottom: 2px solid ${green[500]};
    width: 100%;
    top: 26px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    min-height: 54px;
    border-radius: 12px;
    background: ${green[500]};
  }

  .step-counter {
    color: white;
    font-size: 24px;
  }

  .step-name {
    margin-top: -24px;
    background: ${white};
    border-radius: 12px;
    min-height: 181px;
    padding: 32px 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @media ${device.tablet} {
      padding: 32px 16px 24px 16px;
      min-height: 226px;
    }
    @media ${device.desktopLG} {
      min-height: 254px;
    }
    @media ${device.desktopXL} {
      min-height: 228px;
    }
  }

  .stepper-item:last-child {
    .step-name {
      margin-right: 0;
      width: 100%;
    }
  }

  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
`
