import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const IconList = styled.div`

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
  }
`
