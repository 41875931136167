import React, { useState, useLayoutEffect } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import ScrollTo from 'src/components/ScrollTo'
import { WIDTH_MD } from 'src/styles/breakpoints'

import Stepper from './Stepper/_index'
import StepperHorizontal from './StepperHorizontal/_index'

import { Button } from '../../style'
import { Section } from './style'

const dataJson = [
  {
    title: 'Mãos à obra',
    disclaimer: 'Sua empresa fornece um produto ou serviço em que o pagamento será efetuado no futuro.',
  },
  {
    title: 'Parceria certa',
    disclaimer: 'Você procura o Inter Empresas buscando antecipar o recebimento desses valores, a fim de manter o fluxo de caixa constante.',
  },
  {
    title: 'Mais dinheiro em caixa',
    disclaimer: 'Com a antecipação de recebíveis, sua empresa ganha mais força para expandir os negócios com a tranquilidade que merece.',
  },
  {
    title: 'Levando sua empresa além',
    disclaimer: 'Com esse fortalecimento, fica ainda mais fácil fechar mais parcerias e manter o seu negócio sempre relevante.',
  },
]

const VejaComoAdiantamentoFunciona = () => {
  const [ isMobile, setIsMobile ] = useState(false)
  const windowWidth = useWidth(300)
  const isMobileSize = windowWidth < WIDTH_MD

  useLayoutEffect(() => {
    setIsMobile(isMobileSize)
  }, [ windowWidth ])

  return (
    <Section
      id='veja-como-o-adiantamento-funciona'
      className='py-5 pb-md-0 bg-grayscale--100'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-10 mb-4 mb-md-5 mx-md-auto'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-center mb-2 text-grayscale--500'>
              Veja como o adiantamento de recebíveis funciona no Inter Empresas
            </h2>
          </div>
          <div className='col-12'>
            {isMobile
            ? <Stepper data={dataJson} />
            : <StepperHorizontal
                data={dataJson}
                customTextStyle='fs-14 lh-17 fs-lg-16 lh-lg-19 fw-400 text-grayscale--500 text-center'
              />
            }
          </div>
          <div className='col-12 col-md-6 mt-3 mt-md-5 mx-md-auto d-none d-md-block'>
            <ScrollTo
              to='#solicite-sua-proposta'
              section='dobra_04'
              sectionName='Veja como o adiantamento de recebíveis funciona no Inter Empresas'
              elementName='Antecipar com o Inter'
              title='Antecipar com o Inter'
            >
              <Button
                className='text-none'
              >
                Antecipar com o Inter
              </Button>
            </ScrollTo>
          </div>
          <div className='d-none d-md-block div-bg-bottom' />
        </div>
      </div>
    </Section>
  )
}

export default VejaComoAdiantamentoFunciona
