import { grayscale, green, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Step = styled.div`
  position: relative;
  min-height: 1em;
  color: gray;

  .caption {
    font-size: 14px;
    line-height: 17px;
    color: ${grayscale[500]};
    font-family: Inter;
  }
`

export const Circle = styled.div`
  background: ${green[500]};
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 12px;
  color: ${white};
  text-align: center;
  font-family: 'Sora';
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 65px;
`

export const StepContainer = styled.div`

  ${Step} + ${Step} {
    margin-top: 36px;
  }

  ${Step} > div:first-child {
    position: static;
    height: 0;
  }

  .content {
    background: ${white};
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: Sora;
    min-height: 175px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  ${Step} > div:not(:first-child) {
    margin-left: 35px;
    padding: 14px 16px 14px 30px;
    border-radius: 12px;
  }

  ${Circle}:after {
    content: '';
    position: absolute;
    display: block;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 105px;
    width: 2px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: ${green[500]};
  }

  ${Step}:last-child ${Circle}:after {
    display: none;
  }
`
