import styled from 'styled-components'
import { white, gray, orange, green, grayscale } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`

  .form-hooks > .body-form {
    flex-wrap: wrap;

    label {
      color: ${white};
    }

    input {
      background: ${grayscale[100]};
      border-radius: 8px;
      border: 0 none;
      color: #505050;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      line-height: 17px;
      padding: 0 10px;

      &.hasError {
        border: 1px solid red;
      }
    }

    span.select {
      background: ${gray['400']};
      border-radius: 8px;
      display: block;
      position: relative;
      outline: none;
      overflow: hidden;
      width: 100%;

      &:after {
        content: "";
        border-color: transparent transparent ${green[500]} ${green[500]};
        border-style: solid;
        border-width: 2px;
        height: 9px;
        margin-top: -7px;
        padding: 0;
        pointer-events: none;
        position: absolute;
        right: 14px;
        top: 50%;
        transform: rotate(315deg);
        transition: all .2s ease-in-out;
        width: 9px;
      }

      select {
        appearance: none;
        background: ${white};
        border: 0;
        border-radius: ${rounded['1']};
        color: ${gray['900']};
        cursor: pointer;
        font-size: 14px;
        padding: 8.5px;
        width: 100%;
      }
    }

    button.bg-orange--linear {
      background: linear-gradient(7.83deg, ${orange.dark} 0%, ${orange.base} 100%);
      border-radius: 8px;
      height: 44px;
      padding: 0 10px;
      max-width: 100%;

      &:disabled {
        color: ${grayscale['300']} !important;
        background: ${grayscale['200']}
      }
    }

    .form-label-check {
      
      @media ${device.tablet} {
        padding-top: 4px;
      }

      &::before, &::after {
        display: block;
        position: absolute;
        content: '';
      }

      &::before {
        border: 2px solid ${gray['500']};
        border-radius: 3px;
        height: 20px;
        left: 0;
        top: 4px;
        width: 20px;

        @media ${device.tablet} {
          top: 2px;
        }
      } 
      
      a {
        &:hover {
          color: ${gray['600']};
        }
      }
    }

  .form-input-check {
    height: 0;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
    &::before {
      background: linear-gradient(7.83deg, ${orange.dark} 0%, ${orange.base} 100%);
      border: 1px solid ${orange.dark};
    }
    &::after {
      left: 7px;
      top: 7px;
      width: 6px;
      height: 10px;
      border: solid ${white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

        @media ${device.tablet} {
            top: 6px;
          }
        }
      }
    }
  }
`
