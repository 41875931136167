import React from 'react'
import { StepperContainer } from './style'

interface IStepperProps {
  data: { title: string; disclaimer: string }[];
  customTextStyle?: string;
}

const StepperHorizontal = ({ data, customTextStyle }: IStepperProps) => {
  return (
    <StepperContainer>
      {data?.map((d: {title: string; disclaimer: string}, index: number) => (
        <div key={index} className='stepper-item'>
          <div className='step-counter'>{index + 1}</div>
          <div>
            <div className={`step-name ${customTextStyle ? customTextStyle : 'fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500'}`}>
              <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600 text-center mb-3 mb-lg-2'>{d.title}</h3>
              <p className='fs-12 lh-14 fs-lg-16 lh-lg-20 text-grayscale--500 mb-0'>{d.disclaimer}</p>
            </div>
          </div>
        </div>
      ))}
    </StepperContainer>
  )
}

export default StepperHorizontal
