import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import useUtms from 'src/hooks/useUtms'

import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import OnlineEsemBurocracias from './sections/online-e-sem-burocracias/_index'
import SuaParceriaSimplificada from './sections/sua-parceria-simplificada/_index'
import VejaComoAdiantamentoFunciona from './sections/veja-como-funciona/_index'
import ConhecaQuemSimplifica from './sections/conheca-quem-simplifica/_index'
import AntecipeComInter from './sections/antecipe-com-inter/_index'
import AindaNaoTemConta from './sections/ainda-nao-tem-conta/_index'

import { Wrapper } from './style'

const AntecipacaoRecebiveis = () => {
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Wrapper>
      <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
        <Hero />
        <OnlineEsemBurocracias />
        <SuaParceriaSimplificada />
        <VejaComoAdiantamentoFunciona />
        <ConhecaQuemSimplifica />
        <AntecipeComInter />
        <AindaNaoTemConta />
      </Layout>
    </Wrapper>
  )
}

export default AntecipacaoRecebiveis
