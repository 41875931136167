/* eslint-disable no-console */
import React, { useState, MouseEvent, ChangeEvent, useEffect } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as URLS from 'src/config/api/Urls'
import AcceptTerms from 'src/components/AcceptTerms'

import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'

import successImg from 'src/assets/images/shared/sucesso-pig-checked.svg'
import errorImg from 'src/assets/images/temps/pig-error.png'

import { Container } from './style'

import { IFormValues, contactReceivablesForm } from './types'

const Form = ({ section, elementAction, elementName, sectionName }: contactReceivablesForm) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { register, errors, handleSubmit, setValue, watch }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ acceptPromotion, setAcceptPromotion ] = useState(false)

  const HandleDataLayer = (evt: string) => {
    sendDatalayerEvent({
      section: section,
      element_action: elementAction,
      element_name: elementName,
      section_name: sectionName,
      c_page: window.location.href,
      step: evt,
    })
  }

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = [
      {
      ...data,
      campanha: 'Antecipação de Recebíveis Inter Empresas',
      CpfCnpj: data.CpfCnpj.replace(/[^\w\s]/gi, '').replace(' ', ''),
      conteudo02: accept && data.conteudo02.replace(/[^\w\s]/gi, '').replace(' ', ''),
      conteudo03: acceptPromotion ? 'Sim' : 'Não',
      aceite: true,
      },
    ]

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setSent(true)
      HandleDataLayer('success')
    } catch (e) {
      setError(true)
      HandleDataLayer('null')
    }
  }

  const watchForm = watch()

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setAccept(false)
    setError(false)
    setSent(false)
    setLoading(false)
    setAcceptPromotion(false)
    sendDatalayerEvent({
      section: section,
      element_action: 'click button',
      element_name: 'Voltar',
      section_name: sectionName,
      c_page: window.location.href,
    })
  }

  function handlePromotionCheck (evt: ChangeEvent<HTMLInputElement>) {
    const inputValuePromotion = evt.currentTarget.checked
    setAcceptPromotion(inputValuePromotion)
  }

  useEffect(() => {
    setSent(sent)
    setError(error)

    if (watchForm.nome === '') {
      setAccept(false)
    } else if (watchForm.email === '') {
      setAccept(false)
    } else if (watchForm.conteudo01 === '') {
      setAccept(false)
    } else if (watchForm.conteudo02 === '') {
      setAccept(false)
    } else if (watchForm.CpfCnpj === '') {
      setAccept(false)
    } else {
      setAccept(true)
    }
  }, [ sent, error, watchForm ])

  if (sent) {
    return (
      <div className='text-center mt-4 mt-xl-0'>
        <img src={successImg} alt='Imagem de sucesso' />
        <h3 className='fs-20 lh-24 text-white pt-4 mt-lg-3 fw-600'>Recebemos o seu contato</h3>
        <p className='fs-14 fs-md-18 lh-18 lh-md-22 text-white mb-4'>
          Vamos retornar por e-mail ou telefone. Fique atento!
        </p>
        <button
          className='btn text-white bg-orange--linear mt-3 w-100 text-uppercase'
          title='Clique aqui par voltar'
          onClick={handleReturn}
        >Voltar
        </button>
      </div>
    )
  }

  if (error) {
    return (
      <div className='text-center'>
        <img src={errorImg} alt='Imagem de erro' className='mb-4' />
        <h3 className='fs-20 lh-24 mb-5 text-white'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
        <p className='fs-14 lh-18 text-white'>
          Verifique a sua conexão com a internet e tente novamente. <br />
          <button
            className='border-0 bg-transparent text-white'
            title='Clique aqui para voltar'
            onClick={handleReturn}
          > Clique aqui
          </button>para voltar.
        </p>
      </div>
    )
  }

  return (
    <Container>
      <form
        className='form-hooks'
        onSubmit={handleSubmit(sendForm)}
      >
        <div className='body-form d-lg-flex'>
          <div className='col-12 pb-2 d-flex flex-column px-0'>
            <label htmlFor='nome' className='fs-12 lh-15 fw-600 text-white'>Nome</label>
            <input
              ref={register({
                required: 'Nome obrigatório',
                validate: {
                  isName: (value: string) => Validations.name(value) || 'Insira um nome com mais de 5 caracteres',
                },
              })}
              name='nome'
              id='nome'
              type='text'
              maxLength={80}
              placeholder='Digite o nome completo'
            />
            { errors.nome && <p className='fs-12 mt-1 mb-0 text-white'>{errors.nome.message}</p> }
          </div>
          <div className='col-12 col-lg-6 px-0'>
            <div className='col-12 pb-2 d-flex flex-column mt-2 px-0'>
              <label htmlFor='email' className='fs-14 lh-18 text-grayscale--400'>E-mail</label>
              <input
                ref={register({
                  required: 'Digite um e-mail válido',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'E-mail inválido',
                  },
                })}
                name='email'
                id='email'
                type='text'
                maxLength={80}
                placeholder='Informe seu e-mail'
              />
              {errors.email && <p className='fs-12 mt-1 mb-0 text-white'>{errors.email.message}</p>}
            </div>
          </div>
          <div className='col-12 col-lg-6 px-0 pl-lg-4'>
            <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
              <label htmlFor='conteudo02' className='fs-12 lh-15 fw-600 text-white'>Telefone</label>
              <input
                ref={register({
                  required: 'Telefone obrigatório',
                  pattern: {
                    value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                    message: 'Telefone inválido',
                  },
                })}
                name='conteudo02'
                id='conteudo02'
                type='tel'
                placeholder='Informe o telefone'
                maxLength={15}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('conteudo02', Masks.MaskPHONE(event.currentTarget.value))}
              />
              { errors.conteudo02 && <p className='fs-12 mt-1 mb-0 text-white'>{errors.conteudo02.message}</p> }
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl-12 px-0'>
            <div className='col-12 pb-3 pb-lg-0 d-flex flex-column px-0'>
              <label htmlFor='conteudo01' className='fs-12 lh-15 fw-600 text-white'>Empresa Parceira</label>
              <span className='select'>
                <select
                  name='conteudo01' id='conteudo01'
                  ref={register({ required: 'Escolha ao menos uma empresa' })
                  }
                >
                  <option disabled>Selecione a empresa</option>
                  <option value='PortalMRV'>MRV</option>
                  <option value='Vale'>Vale</option>
                  <option value='Petrobras'>Petrobras</option>
                  <option value='Mosaic'>Mosaic</option>
                  <option value='AngloGold'>AngloGold</option>
                  <option value='Stellantis'>Stellantis</option>
                  <option value='Outros'>Outros</option>
                </select>
              </span>
              { errors.conteudo01 && <p className='fs-12 mt-1 mb-0 text-white'>{errors.conteudo01.message}</p> }
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl-12 px-0 pl-lg-4 pl-xl-0 mt-xl-3'>
            <div className='col-12 pb-3 d-flex flex-column px-0'>
              <label htmlFor='cnpj' className='fs-12 lh-15 fw-600 text-white'>CNPJ</label>
              <input
                ref={register({
                  required: 'CNPJ obrigatório',
                  validate: {
                    isCnpj: (value: string) => Validations.cnpj(value) || 'CNPJ Inválido',
                  },
                })}
                name='CpfCnpj'
                id='CpfCnpj'
                type='tel'
                placeholder='Digite apenas números'
                onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('CpfCnpj', Masks.MaskCNPJ(event.currentTarget.value))}
              />
              { errors.CpfCnpj && <p className='fs-12 text-white pt-1'>{errors.CpfCnpj.message}</p> }
            </div>
          </div>
          <div className='col-12 d-flex flex-column px-0'>
            <label htmlFor='descricao' className='fs-12 lh-15 fw-600 text-white'>Mensagem</label>
            <input
              ref={register()}
              name='descricao'
              id='descricao'
              type='text'
              maxLength={300}
              placeholder='Digite sua mensagem'
            />
          </div>
          <div className='col-12 d-flex pt-md-1 mt-3 px-0'>
            <AcceptTerms linkColor='#ffffff' accept={accept} setAccept={setAccept} name='acceptTermsReceivablesForm' />
          </div>
          <div className='col-12 d-flex pb-4'>
            <input
              name='conteudo03'
              id='conteudo03'
              type='checkbox'
              value={`${acceptPromotion}`}
              className='form-input-check'
              onChange={(evt: ChangeEvent<HTMLInputElement>) => handlePromotionCheck(evt)}
            />
            <label className='form-label-check fs-14 lh-17 ml-3 ml-md-1' htmlFor='conteudo03'>
              Quero receber promoções e informativos do Inter.
            </label>
          </div>
          <div className='col-12 px-0'>
            <button
              type='submit'
              className='btn text-white bg-orange--linear w-100 text-none'
              title='Enviar' disabled={!accept || loading}
            >
              {loading ? 'ENVIANDO...' : 'Enviar'}
            </button>
          </div>
        </div>
      </form>
    </Container>
  )
}

export default Form
