import React from 'react'
import { StepContainer, Step, Circle } from './styles'
interface IStepperProps {
  data: {title: string; disclaimer: string}[];
}

const Stepper = ({ data }: IStepperProps) => {
  return (
    <StepContainer>
      {data?.map((content: {title: string; disclaimer: string}, i: number) => (
        <Step key={i}>
          <div className='mr-2'>
            <Circle>{i + 1}</Circle>
          </div>
          <div className='content'>
            <div className='fs-16 lh-20 text-grayscale--500 fw-600 mb-2'>{content.title}</div>
            <div className='caption'>{content.disclaimer}</div>
          </div>
        </Step>
      ))}
    </StepContainer>
  )
}

export default Stepper
