import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      headerAntecipacaoRecebiveis: imageSharp(fluid: {originalName: { regex: "/tenha-mais-capital-de-giro/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      } 
      suaParceriaSimplificadaMobileTablet: imageSharp(fluid: {originalName: { regex: "/sua-parceria-simplificada-mobile-tablet/" }}) {
        fluid(maxWidth: 337, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      suaParceriaSimplificadaDesktopLgXl: imageSharp(fluid: {originalName: { regex: "/sua-parceria-simplificada-desktop-lg-xl/" }}) {
        fluid(maxWidth: 454, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      aindaNaoTemContaMobileTablet: imageSharp(fluid: {originalName: { regex: "/ainda-nao-tem-conta-mobile-tablet/" }}) {
        fluid(maxWidth: 336, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      aindaNaoTemContaDesktopLgXl: imageSharp(fluid: {originalName: { regex: "/ainda-nao-tem-conta-desktop-lg-xl/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      antecipeComOInter: imageSharp(fluid: {originalName: { regex: "/antecipe-com-o-inter/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      } 
      empresasParceirasInter: imageSharp(fluid: {originalName: { regex: "/empresas-parceiras-inter/" }}) {
        fluid(maxWidth: 430, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      seuParceiroInter: imageSharp(fluid: {originalName: { regex: "/seu-parceiro-inter/" }}) {
        fluid(maxWidth: 612, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      mrv: imageSharp(fluid: {originalName: { regex: "/logo-mrv/" }}) {
        fluid(maxWidth: 174, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      gerdau: imageSharp(fluid: {originalName: { regex: "/logo-gerdau/" }}) {
        fluid(maxWidth: 156, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      mosaic: imageSharp(fluid: {originalName: { regex: "/mosaic/" }}) {
        fluid(maxWidth: 123, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      fca: imageSharp(fluid: {originalName: { regex: "/logo-fca/" }}) {
        fluid(maxWidth: 114, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      petrobras: imageSharp(fluid: {originalName: { regex: "/logo-petrobras/" }}) {
        fluid(maxWidth: 208, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      usiminas: imageSharp(fluid: {originalName: { regex: "/logo-usiminas/" }}) {
        fluid(maxWidth: 147, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      vale: imageSharp(fluid: {originalName: { regex: "/logo-vale/" }}) {
        fluid(maxWidth: 122, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      yara: imageSharp(fluid: {originalName: { regex: "/logo-yara/" }}) {
        fluid(maxWidth: 66, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      corteva: imageSharp(fluid: {originalName: { regex: "/logo-corteva/" }}) {
        fluid(maxWidth: 210, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      minervaFoods: imageSharp(fluid: {originalName: { regex: "/logo-minerva-foods/" }}) {
        fluid(maxWidth: 278, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      carrefour: imageSharp(fluid: {originalName: { regex: "/logo-carrefour/" }}) {
        fluid(maxWidth: 161, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      aperam: imageSharp(fluid: {originalName: { regex: "/logo-aperam/" }}) {
        fluid(maxWidth: 121, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      cnh: imageSharp(fluid: {originalName: { regex: "/logo-cnh/" }}) {
        fluid(maxWidth: 87, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      unidas: imageSharp(fluid: {originalName: { regex: "/logo-unidas/" }}) {
        fluid(maxWidth: 151, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
