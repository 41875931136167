import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ContentHeader = styled.section`
  min-height: 377px;

  @media ${device.tablet} {
    min-height: 415px;
  }
  @media ${device.desktopLG} {
    min-height: 500px;
  }
  @media ${device.desktopXL} {
    min-height: 648px;

    p {
      text-align: justify;
    }
  }
`
