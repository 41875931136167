import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'

export const Section = styled.section`
  min-height: 332px ;

  @media (min-width: ${breakpoints.md}){
    min-height: 478px;
  }
  @media (min-width: ${breakpoints.lg}){
    min-height: 618px;
  }
  @media (min-width: ${breakpoints.xl}){
    min-height: 719px;
  }

  .react-multi-carousel-track {
    padding-bottom: 0;
  }
`

export const Card = styled.div`
  background: ${green[500]};
  border-radius: 8px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  & + div {
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.md}){
    height: 112px;
  }
  @media (min-width: ${breakpoints.lg}){
    width: 200px;
    height: 142px;
  }
  @media (min-width: ${breakpoints.xl}){
    height: 187px;
  }

  img{
    max-width: 100%;
    max-height: 100%;
  }
`
