import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`

  .div-bg-bottom {
    
    @media ${device.tablet} {
      width: 100%;
      background: ${grayscale[100]};
      height: 40px;
      z-index: 5;
    }
    
    @media ${device.desktopLG} {
      height: 64px;
    }
    @media ${device.desktopXL} {
      height: 94px;
    }
  }
`
